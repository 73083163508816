import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { default as IconClose } from './../../../static/icons/icon--close.svg';
import './languageModal.scss';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import {
  getBrowserWindow,
  getLanguageCodeFromCookie,
  getregionNameFromCookie,
  isSCExperienceEditorActive,
  setLanguageCodeToCookie,
} from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import Button from '../../../globals/buttons/Button';

const LanguageModal = ({ data = {}, showModal, closeModal, setLocale, headerHeight }) => {
  const [language, setLanguage] = useState('');

  const [showLanguageSelect, setShowLanguageSelect] = useState(false);

  const { fields = {} } = data;
  const {
    countriesCtaLink = {},
    applyCtaText,
    countriesCtaText,
    globalLinks,
    placeholderText,
    title,
  } = fields;

  const handleLanguageChange = (event) => {
    setShowLanguageSelect(true);
    setLanguage(event.target.value);
  };

  const savePreferences = () => {
    // Set the cookie for 30 days
    if (language) {
      setLanguageCodeToCookie(language);
    }

    setLocale(language);
    if (getBrowserWindow && !isSCExperienceEditorActive) {
      window?.dataLayer?.push({
        event: 'preferences_save',
        preferences_language: getLanguageCodeFromCookie(),
        preferences_region: getregionNameFromCookie(),
      });
    }
  };

  return (
    <div
      className={`language_modal ${showModal ? 'is-active' : ''}`}
      style={{ marginTop: `calc(${headerHeight}px + 2rem)` }}
      {...ComponentDataAttributes('LanguageModal')}
    >
      <div className="language_modal__background" onClick={closeModal}></div>
      <div className="language_modal__content">
        <div className="language_modal__inner">
          <div className="language_modal__header">
            <button
              className="language_modal__close"
              onClick={() => {
                closeModal();
              }}
            >
              <img src={IconClose} alt="close" />
            </button>
          </div>
          <div className="language_modal__body">
            <h3 className="title">
              <TextSitecoreField field={title} />
            </h3>
            <div className="dropdown__language select">
              <div className="form-block-wrapper">
                <div className={`form-block ${showLanguageSelect ? 'select-active' : ''} `}>
                  <select
                    id={'select_language'}
                    className="select-language"
                    value={language}
                    onChange={handleLanguageChange}
                  >
                    <option disabled value="">
                      <TextSitecoreField field={placeholderText} />
                    </option>
                    {globalLinks &&
                      globalLinks?.map((gl) => {
                        return (
                          gl?.fields?.languages &&
                          gl?.fields?.languages?.map((language, lIndex) => {
                            return (
                              <option
                                key={`${language?.fields?.name?.value}_${lIndex}`}
                                value={language?.fields?.code?.value}
                              >
                                <span className="language-name-dp-item">
                                  {language?.fields?.name?.value}
                                </span>
                              </option>
                            );
                          })
                        );
                      })}
                  </select>
                  <label className={'label_title'} htmlFor={'select_language'}>
                    <TextSitecoreField field={placeholderText} />
                  </label>
                </div>
              </div>
            </div>

            {language && (
              <Button
                text={applyCtaText?.value || 'Apply'}
                variant="primary-button"
                cssClass="black"
                fontSize="medium"
                arrow={false}
                BtnFunction={() => {
                  if (language) {
                    savePreferences();
                    closeModal();
                  }
                }}
                componentName="LanguageModal"
              />
            )}
          </div>
        </div>
        <div className="language_modal__footer">
          <Button
            text={countriesCtaText && countriesCtaText?.value ? countriesCtaText?.value : ''}
            cssClass="white"
            variant="text-button"
            arrow={true}
            linkType={countriesCtaLink?.value?.linktype}
            linkFields={countriesCtaLink || {}}
            BtnFunction={() => {
              window.location.pathname = countriesCtaLink?.value?.href;
              closeModal();
            }}
            componentName="LanguageModal"
          />
        </div>
      </div>
    </div>
  );
};

export default LanguageModal;

LanguageModal.defaultProps = {
  showModal: false,
  closeModal: () => {},
  setLocale: () => {},
  setShowModal: () => {},
};

LanguageModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  setLocale: PropTypes.func,
  setShowModal: PropTypes.func,
  cookiesRemoved: PropTypes.func,
};
